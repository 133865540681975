import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { DataTable, useLoader } from "stm-frontend-library";
import { getJournals, showErrorToast } from "../../helpers";
import { columns } from "./columns";

const Journals = () => {
    const [data, setData] = useState([]);
    const { showLoader, hideLoader, isLoading } = useLoader();

    useEffect(() => {
        const fetchData = async () => {
            try {
                showLoader();
                const { data: response } = await getJournals();
                setData(response?.journals ?? []);
            } catch (error) {
                showErrorToast();
            } finally {
                hideLoader();
            }
        }
        fetchData();

    }, [])
    return (
        <MDBContainer className="my-5">
            <DataTable
                isLoading={isLoading}
                rows={data}
                columns={columns}
                enableRowSelection={true}
                onSelectionChange={(selected) => console.log("Selected rows:", selected)}
            />
        </MDBContainer>
    )
}

export default Journals;