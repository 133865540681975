import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarLink,
  MDBContainer,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { useAuth } from "./Auth";
import styled from "styled-components";
import { defaultColor } from "../constants";
import { checkIsAdmin } from "../helpers";

const NavbarWrapper = styled.div`
  .navbar {
    // border-top: 3px solid white;
    // background-color: ${process.env.REACT_APP_PUBLISHER_COLOR ||
  defaultColor};
  }

  .nav-item .nav-link a {
    color: rgba(255, 255, 255, 0.55) !important;
  }

  .nav-item .nav-link a:hover {
    filter: brightness(75%);
  }

  .nav-item .nav-link.active a {
    color: white !important;
  }

  .nav-item .nav-link.disabled a {
    filter: brightness(50%);
  }
`;

const Navbar = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { logout, isFeatureEnabled } = useAuth();

  const toggleCollapse = () => {
    setShow((prev) => !prev);
  };

  const navbarItems = [];

  navbarItems.push({
    label: "Screened manuscripts",
    path: "/screened-manuscripts",
  });

  if (isFeatureEnabled("dupsub")) {
    navbarItems.push({
      label: "Duplicate submissions",
      path: "/duplicate-submissions",
    });
  }

  if (isFeatureEnabled("clearskies")) {
    navbarItems.push({
      label: "ClearSkies Papermill Alarm: Public",
      path: "/clearskies",
    });
  }

  if (isFeatureEnabled("pubpeer")) {
    navbarItems.push({
      label: "Pubpeer",
      path: "/pubpeer",
    });
  }

  if (isFeatureEnabled("referenceAnalysis")) {
    navbarItems.push({
      label: "Reference analysis",
      path: "/reference-analysis",
    });
  }

  if (isFeatureEnabled("textAnalysis")) {
    navbarItems.push({
      label: "Text analysis",
      path: "/text-analysis",
    });
  }

  if (isFeatureEnabled("metadataAnalysis")) {
    navbarItems.push({
      label: "Metadata analysis",
      path: "/metadata-analysis",
    });
  }

  if (isFeatureEnabled("watchlist")) {
    navbarItems.push({
      label: "Watchlist",
      path: "/watchlist",
    });
  }

  // navbarItems.push({
  //   label: 'Full screening DEMO',
  //   path: '/full-screening'
  // })

  const isAdmin = checkIsAdmin();

  return (
    <NavbarWrapper>
      <MDBNavbar expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={show}>
            <MDBNavbarNav>
              {navbarItems.map((item) => (
                <MDBNavbarItem key={item.label}>
                  <MDBNavbarLink
                    active={item.path === location.pathname}
                    className={`nav-link px-3 ${item.path === location.pathname && "fw-bolder"
                      }`}
                    onClick={() => navigate(item.path)}
                  >
                    {item.label}
                  </MDBNavbarLink>
                </MDBNavbarItem>
              ))}
            </MDBNavbarNav>
          </MDBCollapse>
          <div className="d-flex">
            <MDBDropdown>
              <MDBDropdownToggle
                tag="div"
                className="nav-link d-flex align-items-center"
                role="button"
                style={{ padding: 5 }}
              >
                <MDBIcon color="primary" fas icon="user-circle" size="2x" />
              </MDBDropdownToggle>
              <MDBDropdownMenu>

                <MDBDropdownItem tag="button" className="dropdown-item" onClick={() => navigate("/users")}>
                  <MDBIcon className="me-2" icon="users-cog" /> Users
                </MDBDropdownItem>
                <MDBDropdownItem tag="button" className="dropdown-item" onClick={() => navigate("/journals")}>
                  <MDBIcon className="me-2" fas icon="layer-group" />
                  Journals
                </MDBDropdownItem>
                {isAdmin ? (
                  <MDBDropdownItem tag="button" className="dropdown-item" onClick={() => navigate("/settings")}>
                    <MDBIcon className="me-2" icon="cog" /> Settings
                  </MDBDropdownItem>
                ) : <></>}
                <MDBDropdownItem tag="button" className="dropdown-item" onClick={logout}>
                  <MDBIcon className="me-2" icon="sign-out-alt" /> Logout
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>
        </MDBContainer>
      </MDBNavbar>
    </NavbarWrapper>
  );
};

export default Navbar;
