import {
    MDBContainer,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle,
    MDBTextArea,
    MDBBtn
} from "mdb-react-ui-kit";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
    convertISOToReadableDate,
    getJournalRules,
    getJournalRulesVersions,
    updateJournalRules,
    showErrorToast,
    showSuccessToast
} from "../../../helpers";
import { useLoader } from "stm-frontend-library";

const VersionInfo = styled.div`
    margin-top: 0.5rem;
    color: #6c757d;
    font-size: 0.875rem;

    div {
        margin-bottom: 0.25rem;
    }

    strong {
        font-weight: 600;
    }
`;

const RoutingToolsTab = () => {
    const [journalRules, setJournalRules] = useState(null);
    const [lastJournalRulesVersion, setLastJournalRulesVersion] = useState(null);
    const [editedRules, setEditedRules] = useState('');
    const [isJsonValid, setIsJsonValid] = useState(true);

    const textAreaRef = useRef(null);
    const { hideLoader, showLoader } = useLoader();

    const fetchData = async () => {
        try {
            showLoader();
            const [journalRulesResponse, journalRulesVersionResponse] = await Promise.all([
                getJournalRules(),
                getJournalRulesVersions()
            ]);

            const rules = journalRulesResponse.data?.rules;
            setJournalRules(rules);
            setEditedRules(JSON.stringify(rules, null, 2));
            setLastJournalRulesVersion(journalRulesVersionResponse.data?.versions?.[0]);
        } catch (error) {
            showErrorToast();
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [editedRules]);

    const handleChange = (e) => {
        const value = e.target.value;
        setEditedRules(value);
        try {
            JSON.parse(value);
            setIsJsonValid(true);
        } catch {
            setIsJsonValid(false);
        }
    };

    const handleSubmit = async () => {
        try {
            showLoader();
            const parsed = JSON.parse(editedRules);
            await updateJournalRules({ rules: parsed });
            showSuccessToast();
            await fetchData();
        } catch (err) {
            showErrorToast();
        } finally {
            hideLoader();
        }
    };

    const renderVersionInfo = () => {
        if (!lastJournalRulesVersion) return null;
        const { timestamp, createdBy } = lastJournalRulesVersion;

        return (
            <VersionInfo>
                {timestamp && (
                    <div>
                        <strong>Last version:</strong> {convertISOToReadableDate(Date(timestamp))}
                    </div>
                )}
                {createdBy && (
                    <div>
                        <strong>Created by:</strong> {createdBy}
                    </div>
                )}
            </VersionInfo>
        );
    };

    return (
        <MDBContainer className="my-5">
            <MDBCol>
                {journalRules && (
                    <MDBCard className="my-3 shadow-sm">
                        <MDBCardHeader>
                            <MDBCardTitle className="mb-0">Journal Rules</MDBCardTitle>
                            {renderVersionInfo()}
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBTextArea
                                label="Rules JSON"
                                id="journalRulesTextarea"
                                value={editedRules}
                                onChange={handleChange}
                                rows={4}
                                inputRef={textAreaRef}
                                className={isJsonValid ? "" : "is-invalid"}
                            />
                            {!isJsonValid && (
                                <div className="text-danger mt-1">
                                    Invalid JSON format
                                </div>
                            )}
                            <MDBBtn
                                className="mt-3"
                                onClick={handleSubmit}
                                disabled={!isJsonValid}
                            >
                                Update Rules
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                )}
            </MDBCol>
        </MDBContainer>
    );
};

export default RoutingToolsTab;
