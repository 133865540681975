import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
  MDBTypography,
} from "mdb-react-ui-kit";
import parser from "html-react-parser";
import styled from "styled-components";
import { InfoTooltip } from "../../../components";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";
import { ApplyAction } from "../../../components/ApplyAction";
import { dropdownOptions } from "../../screened-manuscripts/dropdownOptions";

export const HrefWrapper = styled.span`
  a:link,
  a:visited {
    color: black;
    background-color: yellow;
    text-decoration: none;
  }

  a:hover {
    color: black;
    background-color: yellow;
    text-decoration: underline;
  }

  & mark {
    background-color: #fdfa9e !important;
  }
`;

const StyledMDBCard = styled(MDBCard)`
  background-color: #ebebeb !important;
  box-shadow: none !important;
`;

export const renderField = (label, value) => {
  return value ? (
    <p>
      <strong>{label}:</strong> {value}
    </p>
  ) : null;
};

export const renderAnyHitDetails = (item, index, array) => (
  <div key={index}>
    {renderField("Pattern", item?.pattern)}
    {renderField("Comment", item?.expectedText)}
    {index < array.length - 1 && <hr className="hr" />}
  </div>
);

export const renderIndicationDisplay = (item, index) => {
  return (
    <MDBRow key={index} className={index > 0 ? "mt-4" : ""}>
      {item?.sentence ? (
        <>
          <MDBCol md="9">
            <StyledMDBCard className="px-3 py-4">
              {item.sentence.map((sentence, index) => (
                <div key={index}>
                  <HrefWrapper>{parser(`${sentence}`)}</HrefWrapper>
                </div>
              ))}
            </StyledMDBCard>
          </MDBCol>

          <MDBCol md="3">
            <MDBTypography style={{ color: "grey" }} variant="h6">
              <>
                Indication {(index + 1).toString()}{" "}
                {`(${item.hits} instance${item.hits === 1 ? "" : "s"})`}
              </>
            </MDBTypography>
            {item?.expectedText ? (
              <div style={{ color: "#6699cc" }}>
                <>
                  <b>Established term:</b>
                  <br />
                  &#34;{item.expectedText}&#34;
                </>
                <div className="mt-2">
                  <b>Pattern:</b>
                  <br />
                  &#34;{item.pattern}&#34;
                </div>
              </div>
            ) : null}
          </MDBCol>
        </>
      ) : (
        <div className="ps-2">None found</div>
      )}
    </MDBRow>
  );
};

export const renderCardSection = (
  items,
  title,
  renderFunction,
  sectionKey,
  tooltipText = null,
  className = "mt-3",
  onActionSelect,
  onActionUndo,
  data
) => {
  if (items.length === 0) return null;

  return (
    <MDBCard className={className}>
      <MDBCardHeader className="d-flex align-items-center">
        <ReportActionButtons signals={[sectionKey]} showTooltip={false} />
        <MDBCardTitle className="pt-2 ps-2">
          {title}
          {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
        </MDBCardTitle>
        <div className="ms-auto">
          <ApplyAction
            index={data?.manuscript_id}
            status={data?.submission_status}
            selectAction={onActionSelect}
            undoAction={onActionUndo}
            options={dropdownOptions}
          />
        </div>
      </MDBCardHeader>
      <MDBCardBody>
        {items.map((item, index, array) => renderFunction(item, index, array))}
      </MDBCardBody>
    </MDBCard>
  );
};
