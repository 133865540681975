import Jsontableify from "jsontableify";

import React, { useRef } from "react";
import { useLocation } from 'react-router-dom';
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import {
  capitalizeFirstLetter,
  convertISOToReadableDate,
} from "../../../helpers";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";
import "./DuplicateSubmissionsDataReview.css";
import { dropdownOptions } from "../dropdownOptions";
import { ApplyAction } from "../../../components/ApplyAction";
import DuplicateSubmissionsDiffViewer from "./DuplicateSubmissionsDiffViewer";

const OTHER_PUBLISHER_NAME = 'Other publisher';
const DEEPLINK_PATH = '/dlreport';

const DuplicateSubmissionsDataReview = ({
  data,
  withSubHeader,
  onActionSelect,
}) => {
  const abstractRef = useRef(null);

  const location = useLocation();
  const isDeepLinkRoute = location.pathname.startsWith(DEEPLINK_PATH);

  const formatDate = (date, format = "DD MMM YYYY HH:mm") =>
    date ? convertISOToReadableDate(date, format) : "";

  const getStatusSummary = (item, isOtherPublisher) => {
    const results = [];
    const { submission_id, submission_status, status_updated, archived_timestamp } = item;
    const addTimestamp = !(isOtherPublisher && isDeepLinkRoute);

    const formatMessage = (baseMessage, timestamp) => {
      return addTimestamp && timestamp ? `${baseMessage} (${formatDate(timestamp)})` : baseMessage;
    };

    if (["duplicate confirmed", "suspect paper mill"].includes(submission_status)) {
      results.push(formatMessage("Duplicate Confirmed", status_updated));
    } else if (["false positive", "false match"].includes(submission_status)) {
      results.push(formatMessage("Reported False Positive", status_updated));
    }

    if (!submission_id) {
      results.push(formatMessage("Submission Removed", archived_timestamp));
    }

    return capitalizeFirstLetter(results.join("; "));
  };

  const formatReport = (data) => {
    const isDifferentDate = (date1, date2) => {
      if (!date1 || !date2) return false;
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      return (
        d1.getFullYear() !== d2.getFullYear() ||
        d1.getMonth() !== d2.getMonth() ||
        d1.getDate() !== d2.getDate()
      );
    };

    const formatSubmissionDate = (submittedDate, originalDate) => {
      const formattedSubmitted = formatDate(submittedDate);
      const formattedOriginal =
        originalDate && isDifferentDate(submittedDate, originalDate)
          ? `(original: ${formatDate(originalDate, "DD MMM YYYY")}) `
          : "";

      return `
          <span>
            ${formattedSubmitted} ${formattedOriginal
          ? `<span class="highlighted-status">${formattedOriginal}</span>`
          : ""}
            ${formattedOriginal ? '<span class="tooltip-placeholder"></span>' : ""}
          </span>
        `;
    };

    const formatPublisher = (email, manuscriptId, name) => {
      return email
        ? `<a href="mailto:${email}?subject=${manuscriptId}">${name}</a>`
        : name;
    };

    const formatManuscript = (
      {
        submitted,
        manuscript_id: manuscriptId,
        journal,
        publisher_name: publisherName,
        title,
        feedback_email: feedbackEmail,
        original_submission_date: originalSubmissionDate,
        abstract,
        authors,
        similarity,
        persistent_id,
        status_updated: statusUpdated,
        ...rest
      }
    ) => {
      const {
        title: titleSimilarity,
        authors: authorsSimilarity,
        abstract: abstractSimilarity,
      } = similarity ?? {};

      const isOtherPublisher = publisherName.includes(OTHER_PUBLISHER_NAME);
      const formattedTitle =
        title + (titleSimilarity ? ` (Similarity: ${titleSimilarity}%)` : "");
      const submissionStatus = getStatusSummary(rest, isOtherPublisher);

      const abstractText = abstract ? abstract.slice(0, 200) : "";
      const isAbstractTruncated = abstract && abstract.length >= 200;
      const showSimilarityOnNewLine = abstract && abstract.length <= 100;

      let abstractInfo = {};
      if (abstract || abstractSimilarity) {
        const similarityText = abstractSimilarity
          ? `${abstract ? " (" : ""}Similarity: ${abstractSimilarity}%${abstract ? ")" : ""}`
          : "";

        abstractInfo = {
          Abstract: `${abstractText}${isAbstractTruncated
              ? `... <a href="#" class="view-more-link">View more</a>`
              : ""
            }${showSimilarityOnNewLine ? "<br>" : " "}${similarityText}`,
        };
      }

      let authorsInfo = {};
      if (authors || authorsSimilarity) {
        const similarityText = authorsSimilarity
          ? `${authors ? " (" : ""}Similarity: ${authorsSimilarity}%${authors ? ")" : ""}`
          : "";

        authorsInfo = {
          "Author list": `${authors ? authors.join(", ") : ""}${authorsSimilarity ? `${similarityText}` : ""}`,
        };
      }

      return {
        ...(submissionStatus && {
          Status: `<span class="highlighted-status">${submissionStatus}</span>`,
        }),
        Date: formatSubmissionDate(submitted, originalSubmissionDate),
        Publisher: isOtherPublisher
          ? `<span class="blured-line">${publisherName}</span>`
          : formatPublisher(feedbackEmail, manuscriptId, publisherName),
        Journal: isOtherPublisher
          ? `<span class="blured-line">${journal}</span>`
          : journal,
        ManuscriptID: isOtherPublisher
          ? `<span class="blured-line">${manuscriptId}</span>`
          : manuscriptId,
        Title: formattedTitle,
        ...abstractInfo,
        ...authorsInfo,
      };
    };

    const { matches, trigger } = data;

    const reportData = formatManuscript(trigger);
    const formattedMatches =
      matches?.map((match) =>
        formatManuscript(match)
      ) || null;

    return {
      ...reportData,
      "Matched Manuscripts": formattedMatches,
    };
  };

  const handleActionUndo = async (index) => {
    await onActionSelect(index, null);
  };

  const handleViewMore = (e) => {
    e.preventDefault();
    const target = e.target;
    if (target.classList.contains("view-more-link")) {
      abstractRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderModalContent = (data) => {
    if (!data) return null;

    const reportData = formatReport(data);
    const jt = new Jsontableify();
    const content = jt.toHtml(reportData).html;

    const SHOULD_SHOW_TITLE_DIFF =
      data?.trigger?.authors &&
      data?.matches?.[0]?.authors &&
      data?.trigger?.abstract &&
      data?.matches?.[0]?.abstract;

    return (
      <>
        <div
          className="modal_table_wrapper"
          dangerouslySetInnerHTML={{ __html: content }}
          onClick={handleViewMore}
        />

        <hr className="hr" />
        {SHOULD_SHOW_TITLE_DIFF && (
          <DuplicateSubmissionsDiffViewer
            label="Title"
            oldValue={data?.trigger?.title}
            newValue={data?.matches?.[0]?.title}
            similarity={data?.matches?.[0]?.similarity?.title}
            oldValueTitle={data.trigger?.publisher_name}
            newValueTitle={data.matches[0]?.publisher_name}
          />
        )}
        <DuplicateSubmissionsDiffViewer
          label="Authors list"
          oldValue={data?.trigger?.authors}
          newValue={data?.matches?.[0]?.authors}
          similarity={data?.matches?.[0]?.similarity?.authors}
          oldValueTitle={data.trigger?.publisher_name}
          newValueTitle={data.matches[0]?.publisher_name}
        />
        <DuplicateSubmissionsDiffViewer
          ref={abstractRef}
          label="Abstract"
          oldValue={data?.trigger?.abstract}
          newValue={data?.matches?.[0]?.abstract}
          similarity={data?.matches?.[0]?.similarity?.abstract}
          oldValueTitle={data.trigger?.publisher_name}
          newValueTitle={data.matches[0]?.publisher_name}
        />
      </>
    );
  };

  return (
    <>
      {(!!data?.matches || !!data?.trigger) && (
        <MDBCard className="my-2">
          {withSubHeader && (
            <MDBCardHeader className="d-flex align-items-center">
              <ReportActionButtons
                showTooltip={false}
                signals={["duplicateSubmissions"]}
              />
              <MDBCardTitle className="pt-2 ps-2">
                Duplicate submissions
              </MDBCardTitle>
              <div className="ms-auto">
                <ApplyAction
                  index={data?.manuscript_id}
                  status={data?.submission_status}
                  selectAction={onActionSelect}
                  undoAction={handleActionUndo}
                  options={dropdownOptions}
                />
              </div>
            </MDBCardHeader>
          )}
          <MDBCardBody>{renderModalContent(data)}</MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default DuplicateSubmissionsDataReview;
