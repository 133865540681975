import { API_PATHS } from "./apiPaths";
import { axiosInstance } from "./axiosSetup";

export async function fetchManuscriptCounts() {
  const [duplicateSubmissionsResponse, ambientScreenedResponse] = await Promise.all([
    axiosInstance.post('hub-api/proxy', {
      path: API_PATHS.DUPLICATE_SUBMISSIONS_COUNT_TOTAL,
      method: "get",
    }),
    axiosInstance.post('hub-api/proxy', {
      path: API_PATHS.AMBIENT_SCREENED_COUNT_TOTAL,
      method: "get",
    }),
  ]);

  return {
    duplicateSubmissions: duplicateSubmissionsResponse.data?.total ?? 0,
    ambientScreened: ambientScreenedResponse.data?.total ?? 0,
  };
}

export async function exportScreenedManuscripts(fullData = true) {
  const requestBody = {
    path: API_PATHS.EXPORT_ALL_SCREENED_MANUSCRIPT_TABS,
    method: "get",
  };

  if (fullData) {
    requestBody.queryStringParameters = {
      fulldata: true,
    };
  }

  return axiosInstance.post(`hub-api/proxy`, requestBody);
}
