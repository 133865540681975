import React from "react";
import TextInput from "./TextInput";
import { Field } from "formik";
import { MDBBtn } from "mdb-react-ui-kit";
import { Spinner } from "stm-frontend-library";
import { SSO_BUTTON_LABEL, SSO_URL } from "../constants";

const LoginForm = ({ handleSubmit, isSubmitting }) => {

  const handleSSOClick = () => {
    window.location.href = SSO_URL;
  }

  return (
    <form className="col-10 col-md-4" onSubmit={handleSubmit}>
      <span
        className="d-block text-center mb-3 display-4"
        style={{ fontSize: "2.8rem" }}
      >
        Log in
      </span>
      <Field
        component={TextInput}
        name="username"
        label="Username"
        type="text"
      />
      <Field
        component={TextInput}
        name="password"
        label="Password"
        type="password"
      />
      <div className="text-center mt-4">
        {isSubmitting ? (
          <Spinner />
        ) : (
          <div className="d-grid">
            <MDBBtn className="btn btn-primary" type="submit">
              Login
            </MDBBtn>
            {SSO_BUTTON_LABEL && (
              <MDBBtn type="button" className="btn btn-secondary mt-3" onClick={handleSSOClick} >
                {SSO_BUTTON_LABEL}
              </MDBBtn>
            )}
          </div>
        )}
      </div>
    </form>
  );
};

export default LoginForm;
