import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import styled from "styled-components";
import { useAuth } from "../../../components/Auth";
import { MDBSwitch } from "mdb-react-ui-kit";

export const StyledSwitchWrapper = styled.div`
  label.form-check-label {
    color: #212529 !important; /* Bootstrap default text color */
    opacity: 1 !important;
  }

  .form-check-input:disabled ~ .form-check-label:hover {
    color: #212529 !important;
  }
`;

const DisabledStyledSwitch = (props) => (
    <StyledSwitchWrapper>
        <MDBSwitch {...props} disabled />
    </StyledSwitchWrapper>
);

const SignalsTab = () => {
    const { components } = useAuth();
    const data = {
        publisherProfileForm: {
            components,
        },
    };

    return (
        <MDBContainer className="my-5">
            <MDBRow className="mb-3">
                <MDBCol>
                    <MDBTypography variant="h6" className="mb-3 fw-bold">
                        PubPeer
                    </MDBTypography>
                    <SwitchGroup className="mb-2">
                        <DisabledStyledSwitch
                            checked={data?.publisherProfileForm?.components?.FOCRat ?? false}
                            label="PubPeer hits"
                        />
                    </SwitchGroup>
                </MDBCol>
                <MDBCol>
                    <MDBTypography variant="h6" className="mb-3 fw-bold">
                        ClearSkies Papermill Alarm: Public
                    </MDBTypography>
                    <SwitchGroup className="mb-2">
                        <DisabledStyledSwitch
                            label="Papermill similarity"
                            checked={data?.publisherProfileForm?.components?.CSStat ?? false}
                        />
                    </SwitchGroup>
                </MDBCol>
            </MDBRow>

            <FeatureSection
                title="Cactus Integrity Checks"
                messageWhenHidden="Cactus Integrity Checks are not available for this publisher"
                visible
            >
                <MDBCol>
                    <SwitchGroup>
                        <DisabledStyledSwitch
                            label="Author affiliation relevance"
                            checked={
                                data?.publisherProfileForm?.components?.cactusTool
                                    ?.authorAffiliationRelevance ?? false
                            }
                        />
                        <DisabledStyledSwitch
                            label="Author geographical distribution"
                            checked={
                                data?.publisherProfileForm?.components?.cactusTool
                                    ?.authorGeographicalDistribution ?? false
                            }
                        />
                        <DisabledStyledSwitch
                            label="Age of reference"
                            checked={
                                data?.publisherProfileForm?.components?.cactusTool
                                    ?.ageOfReference ?? false
                            }
                        />
                        <DisabledStyledSwitch
                            label="Reference citation"
                            checked={
                                data?.publisherProfileForm?.components?.cactusTool
                                    ?.referenceCitation ?? false
                            }
                        />
                    </SwitchGroup>
                </MDBCol>
                <MDBCol>
                    <SwitchGroup>
                        <DisabledStyledSwitch
                            label="Citation cartel"
                            checked={
                                data?.publisherProfileForm?.components?.cactusTool
                                    ?.citationCartel ?? false
                            }
                        />
                        <DisabledStyledSwitch
                            label="Self citation"
                            checked={
                                data?.publisherProfileForm?.components?.cactusTool
                                    ?.selfCitation ?? false
                            }
                        />
                        <DisabledStyledSwitch
                            label="Unrelated references"
                            checked={
                                data?.publisherProfileForm?.components?.cactusTool
                                    ?.unrelatedReferences ?? false
                            }
                        />
                    </SwitchGroup>
                </MDBCol>
            </FeatureSection>

            <MDBRow className="mb-3">
                <hr className="hr" />
                <MDBCol>
                    <MDBTypography variant="h6" className="mb-3 fw-bold">
                        Reference analysis
                    </MDBTypography>
                    <SwitchGroup>
                        <DisabledStyledSwitch
                            label="Retraction Watch"
                            checked={data?.publisherProfileForm?.components?.RWtch ?? false}
                        />
                        <DisabledStyledSwitch
                            label="DOI analysis"
                            checked={data?.publisherProfileForm?.components?.CRRF ?? false}
                        />
                    </SwitchGroup>
                </MDBCol>
                <MDBCol>
                    <MDBTypography variant="h6" className="mb-3 fw-bold">
                        Text analysis
                    </MDBTypography>
                    <SwitchGroup>
                        <DisabledStyledSwitch
                            label="Tortured phrases"
                            checked={data?.publisherProfileForm?.components?.TPHrs ?? false}
                        />
                        <DisabledStyledSwitch
                            label="GenAI response"
                            checked={
                                data?.publisherProfileForm?.components?.genAIResponse ?? false
                            }
                        />
                        <DisabledStyledSwitch
                            label="Unnatural text"
                            checked={data?.publisherProfileForm?.components?.MLDet ?? false}
                        />
                    </SwitchGroup>
                </MDBCol>
            </MDBRow>

            <MDBRow className="mb-3">
                <hr className="hr" />
                <MDBCol>
                    <MDBTypography variant="h6" className="mb-3 fw-bold">
                        Metadata analysis
                    </MDBTypography>
                    <DisabledStyledSwitch
                        checked={
                            data?.publisherProfileForm?.components?.wordFileProperties ??
                            false
                        }
                        label="Word file metadata"
                    />
                </MDBCol>
                <MDBCol>
                    <MDBTypography variant="h6" className="mb-3 fw-bold">
                        Duplicate submissions
                    </MDBTypography>
                    <DisabledStyledSwitch
                        checked={data?.publisherProfileForm?.components?.OPScr ?? false}
                        label="Metadata screening"
                    />
                </MDBCol>
            </MDBRow>

            <MDBRow className="mb-3">
                <hr className="hr" />
                <MDBCol>
                    <MDBTypography variant="h6" className="mb-3 fw-bold">
                        Watchlist
                    </MDBTypography>
                    <SwitchGroup>
                        <DisabledStyledSwitch
                            label="Fake affiliation names"
                            checked={data?.publisherProfileForm?.components?.SSPan ?? false}
                        />
                        <DisabledStyledSwitch
                            label="Fake names or e-mail addresses"
                            checked={data?.publisherProfileForm?.components?.SSPidd ?? false}
                        />
                        <DisabledStyledSwitch
                            label="Fake e-mail domains"
                            checked={data?.publisherProfileForm?.components?.SSPfd ?? false}
                        />
                        <DisabledStyledSwitch
                            label="Disposable e-mail domains"
                            checked={data?.publisherProfileForm?.components?.SSPdd ?? false}
                        />
                    </SwitchGroup>
                </MDBCol>
                <MDBCol>
                    <SwitchGroup style={{ marginTop: '33px' }}>
                        <DisabledStyledSwitch
                            label="Bad actors"
                            checked={data?.publisherProfileForm?.components?.SSPba ?? false}
                        />
                        <DisabledStyledSwitch
                            label="Suspect articles"
                            checked={data?.publisherProfileForm?.components?.SSPsa ?? false}
                        />
                        <DisabledStyledSwitch
                            label="Manuscripts offered for sale"
                            checked={data?.publisherProfileForm?.components?.SSPmfs ?? false}
                        />
                        <DisabledStyledSwitch
                            label="Metadata suspects"
                            checked={data?.publisherProfileForm?.components?.SSMeta ?? false}
                        />
                    </SwitchGroup>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export const SwitchGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || "0.7rem"};
`;

const FeatureSection = ({
    title,
    visible = true,
    messageWhenHidden = null,
    className = "mb-3",
    children,
}) => {
    return (
        <MDBRow className={className}>
            <hr className="hr" />
            <MDBTypography variant="h6" className="mb-3 fw-bold">
                {title}
            </MDBTypography>
            {visible ? (
                children
            ) : (
                <MDBTypography className="text-muted fst-italic">
                    {messageWhenHidden}
                </MDBTypography>
            )}
        </MDBRow>
    );
};

export default SignalsTab;
