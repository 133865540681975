import React, { useEffect, useState, useMemo } from "react";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { useAuth } from "../../components/Auth";
import { ReferenceAnalysisReviewModal } from "./components";
import {
  convertISOToReadableDate,
  getReferenceAnalysisReports,
  showErrorToast,
  extractUniqueScreeningOutcomeTypes
} from "../../helpers";
import { getDataTableColumns } from "./columns";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { CountCard } from "../../components";
import { FilterIconHeader } from "../../components/FilterIconHeader";
import { SwitchForm } from "../../components/SwitchForm";
import { referenceSignals } from "../../constants";
import { exportReferenceAnalysisToExcel } from "./export";
import { DataTable, useLoader } from "stm-frontend-library";

const ReferenceAnalysis = () => {
  const [error, setError] = useState(false);

  const { components } = useAuth();
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [selectedDataRowIndex, setSelectedDataRowIndex] = useState(null);
  const [filterSelection, setFilterSelection] = useState([]);

  const { isLoading, hideLoader, showLoader } = useLoader();

  const dataWithActions = filteredData?.map((element, index) => {
    const screeningOutcomes = element.screening_outcomes || [];

    const typeLabels = referenceSignals.reduce((acc, current) => {
      acc[current.value] = current.label;
      return acc;
    }, {});

    const rawMatchStatus = screeningOutcomes
      .map((outcome) => {
        const { type, items, supplementary_data } = outcome;

        if (typeLabels[type]) {
          let result = `${typeLabels[type]} ${items.length}`;
          if (supplementary_data?.retrievedReferences !== undefined) {
            result += `/${supplementary_data.retrievedReferences}`;
          }
          if (supplementary_data?.totalReferences !== undefined) {
            result += ` (${supplementary_data.totalReferences})`;
          }
          return result;
        }
        return null;
      })
      .filter(Boolean)
      .join("\n");

    const matchStatusElements = screeningOutcomes.map((outcome, idx) => {
      const { type, items, supplementary_data } = outcome;

      if (typeLabels[type]) {
        return (
          <React.Fragment key={idx}>
            <small>
              <span className="text-primary">{typeLabels[type]}</span>{" "}
              {items.length}
              {supplementary_data?.retrievedReferences !== undefined && (
                <span>/{supplementary_data.retrievedReferences}</span>
              )}
              {supplementary_data?.totalReferences !== undefined && (
                <span> ({supplementary_data.totalReferences})</span>
              )}
            </small>
            <br />
          </React.Fragment>
        );
      }

      return null;
    });

    return {
      ...element,
      timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
      match_status: <>{matchStatusElements}</>,
      raw_match_status: rawMatchStatus,
      clickEvent: () => handleRowClick(index),
    };
  });

  const handleRowClick = (index) => {
    setSelectedDataRowIndex(index);
  };

  const handleCloseModal = () => setSelectedDataRowIndex(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(false);
        showLoader();
        const { data: { manuscripts = [], total = 0 } = {} } =
          await getReferenceAnalysisReports();
        setAllData(manuscripts);
        setTotalHits(total);
        const uniqueTypes = extractUniqueScreeningOutcomeTypes(manuscripts);
        const initialFilter = Object.fromEntries(
          uniqueTypes.map((type) => [type, true])
        );
        setFilterSelection(initialFilter);
      } catch (error) {
        showErrorToast();
        setError(true);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(filterSelection).length) {
      const filteredManuscripts = allData.filter((manuscript) =>
        manuscript.screening_outcomes.some(
          (outcome) => filterSelection[outcome.type]
        )
      );
      setFilteredData(filteredManuscripts);
    }
  }, [filterSelection]);

  const options = useMemo(() => {
    return referenceSignals.filter((signal) =>
      components.hasOwnProperty(signal.value)
    );
  }, [components]);

  const handleFilterSubmit = (values) => {
    setFilterSelection(values);
  };

  const handleFilterReset = () => {
    const resetValues = Object.fromEntries(
      Object.keys(filterSelection).map((type) => [type, true])
    );
    setFilterSelection(resetValues);
  };

  const filterComponent = (
    <FilterIconHeader label="Hits" dropdownTitle="Signal Type Selection">
      <SwitchForm
        handleSubmit={handleFilterSubmit}
        handleReset={handleFilterReset}
        initialValues={filterSelection}
        options={options}
      />
    </FilterIconHeader>
  );
  const tableColumns = getDataTableColumns(filterComponent);

  const handleExport = () => {
    exportReferenceAnalysisToExcel(dataWithActions, "ReferenceAnalysis");
  };

  return (
    <MDBContainer className="my-5">
      {selectedDataRowIndex !== null && (
        <ReferenceAnalysisReviewModal
          contentData={filteredData[selectedDataRowIndex]}
          onClose={handleCloseModal}
        />
      )}
      {error && <ErrorMessage />}
      {!error && (
        <>
          <div className="d-flex justify-content-center m-5">
            <CountCard
              count={totalHits}
              title={"ANALYSIS SIGNALS"}
              subtitle={"Screened manuscripts with hits"}
            />
          </div>
          <div className="d-flex justify-content-end mb-3">
            <MDBBtn
              disabled={isLoading}
              color="primary"
              className="px-4"
              onClick={handleExport}
            >
              Export
            </MDBBtn>
          </div>
          <DataTable columns={tableColumns} rows={dataWithActions} isLoading={isLoading} />
        </>
      )}
    </MDBContainer>
  );
};

export default ReferenceAnalysis;
