import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { renderFeetOfClayDetails } from "./PubpeerDataReviewUtils";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";
import { dropdownOptions } from "../../screened-manuscripts/dropdownOptions";
import { ApplyAction } from "../../../components/ApplyAction";

const PubpeerDataReview = ({
  data,
  withSubHeader = false,
  onActionSelect,
  onActionUndo,
}) => {
  const feetOfClayDetector =
    data?.screening_outcomes?.find((x) => x.type === "feetOfClayDetector")
      ?.items ?? [];

  return (
    <>
      {feetOfClayDetector.length > 0 && (
        <MDBCard className="my-2">
          {withSubHeader && (
            <MDBCardHeader className="d-flex align-items-center">
              <ReportActionButtons
                signals={["feetOfClayDetector"]}
                showTooltip={false}
              />
              <MDBCardTitle className="pt-2 ps-2">Pubpeer</MDBCardTitle>
              <div className="ms-auto">
                <ApplyAction
                  index={data?.manuscript_id}
                  status={data?.submission_status}
                  selectAction={onActionSelect}
                  undoAction={onActionUndo}
                  options={dropdownOptions}
                />
              </div>
            </MDBCardHeader>
          )}
          <MDBCardBody>
            {feetOfClayDetector.map((item, index) =>
              renderFeetOfClayDetails(item, index)
            )}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default PubpeerDataReview;
