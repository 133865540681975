export const columns = [
    {
        label: "Journal",
        field: "journalName",
    },
    {
        label: "Subject(s)",
        field: "subject",
    },
];
