import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { DataTable, useLoader } from "stm-frontend-library";
import { getUsers, showErrorToast } from "../../helpers";
import { columns } from "./columns";

const Users = () => {
    const [data, setData] = useState([]);
    const { showLoader, hideLoader, isLoading } = useLoader();

    useEffect(() => {
        const fetchData = async () => {
            try {
                showLoader();
                const { data: response } = await getUsers();
                setData(response ?? []);
            } catch (error) {
                showErrorToast();
            } finally {
                hideLoader();
            }
        };

        fetchData();
    }, []);

    const username = localStorage.getItem('username');
    const mappedData = data
        .map(x => ({
            ...x,
            status: x.name === username ? `👤 (you)` : ''
        }))

    return (
        <MDBContainer className="my-5">
            <DataTable isLoading={isLoading} rows={mappedData} columns={columns} />
        </MDBContainer>
    )
}

export default Users;