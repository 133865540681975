export const columns = [
    {
        label: "Username",
        field: "name",
    },
    {
        label: "Status",
        field: "status",
    }
];
