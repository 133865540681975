export const formatScreeningOutcomeHits = (
  screeningOutcomes,
  signals,
  { 
    separator = "<br>",
    formatCount = (count) => `&nbsp;(x${count})` 
  } = {}
) => {
  if (!screeningOutcomes) return null;

  const labelMap = signals.reduce((acc, {value, label}) => ({
    ...acc, 
    [value]: label
  }), {});

  return screeningOutcomes
    .map(({ type, items }) => {
      const label = labelMap[type];
      if (!label) return null;

      const totalCount = items.some(item => 'hits' in item)
        ? items.reduce((sum, item) => sum + (item.hits || 0), 0)
        : items.length;

      return `${label}${formatCount(totalCount)}`;
    })
    .filter(Boolean)
    .join(separator);
};
