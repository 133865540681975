import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState, useMemo } from "react";

import { useAuth } from "../../components/Auth";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { CountCard } from "../../components";
import { FilterIconHeader } from "../../components/FilterIconHeader";
import { SwitchForm } from "../../components/SwitchForm";
import { TextAnalysisReviewModal } from "./components";

import {
  convertISOToReadableDate,
  showErrorToast,
  extractUniqueScreeningOutcomeTypes
} from "../../helpers";
import { getTextAnalysisReports } from "../../helpers/textAnalysis";
import { getDataTableColumns } from "./columns";
import { formatScreeningOutcomeHits } from "./components/textAnalysisUtils";
import { exportTextAnalysisToExcel } from "./export";

import { textSignals } from "../../constants";
import { DataTable, useLoader } from "stm-frontend-library";

const TextAnalysis = () => {
  const { components } = useAuth();

  const [error, setError] = useState(false);

  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [filterSelection, setFilterSelection] = useState([]);

  const [selectedDataRowIndex, setSelectedDataRowIndex] = useState(null);

  const { isLoading, hideLoader, showLoader } = useLoader();

  const handleRowClick = (index) => {
    setSelectedDataRowIndex(index);
  };

  const handleCloseModal = () => setSelectedDataRowIndex(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(false);
        showLoader();
        const { data: { manuscripts = [], total = 0 } = {} } =
          await getTextAnalysisReports();
        setAllData(manuscripts);
        setTotalHits(total);
        const uniqueTypes = extractUniqueScreeningOutcomeTypes(manuscripts);
        const initialFilter = Object.fromEntries(
          uniqueTypes.map((type) => [type, true])
        );
        setFilterSelection(initialFilter);
      } catch (error) {
        showErrorToast();
        setError(true);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);

  const dataWithActions = filteredData.map((element, index) => ({
    ...element,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    hits: (<span
      dangerouslySetInnerHTML={{
        __html: formatScreeningOutcomeHits(
          element.screening_outcomes,
          textSignals
        )
      }}
    />),
    clickEvent: () => handleRowClick(index),
  }));

  useEffect(() => {
    if (Object.keys(filterSelection).length) {
      const filteredManuscripts = allData.filter((manuscript) =>
        manuscript.screening_outcomes.some((outcome) => filterSelection[outcome.type])
      );
      setFilteredData(filteredManuscripts);
    }
  }, [filterSelection]);

  const options = useMemo(() => {
    return textSignals.filter((signal) =>
      components.hasOwnProperty(signal.value)
    );
  }, [components]);

  const handleFilterSubmit = (values) => {
    setFilterSelection(values);
  };

  const handleFilterReset = () => {
    const resetValues = Object.fromEntries(
      Object.keys(filterSelection).map((type) => [type, true])
    );
    setFilterSelection(resetValues);
  };

  const filterComponent = (
    <FilterIconHeader label="Hits" dropdownTitle="Signal Type Selection">
      <SwitchForm
        handleSubmit={handleFilterSubmit}
        handleReset={handleFilterReset}
        initialValues={filterSelection}
        options={options}
      />
    </FilterIconHeader>
  );

  const tableColumns = getDataTableColumns(filterComponent);

  const handleExport = () => {
    exportTextAnalysisToExcel(dataWithActions, "TextAnalysis");
  };

  return (
    <MDBContainer className="my-5">
      {selectedDataRowIndex !== null && (
        <TextAnalysisReviewModal
          contentData={allData[selectedDataRowIndex]}
          onClose={handleCloseModal}
        />
      )}
      {error && <ErrorMessage />}
      {!error && (
        <>
          <div className="d-flex justify-content-center m-5">
            <CountCard
              count={totalHits}
              title={"ANALYSIS SIGNALS"}
              subtitle={"Screened manuscripts with hits"}
            />
          </div>
          <div className="d-flex justify-content-end mb-3">
            <MDBBtn
              disabled={isLoading}
              color="primary"
              className="px-4"
              onClick={handleExport}
            >
              Export
            </MDBBtn>
          </div>
          <DataTable columns={tableColumns} rows={dataWithActions} isLoading={isLoading} />
        </>
      )}
    </MDBContainer>
  );
};

export default TextAnalysis;
