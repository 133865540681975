import { axiosInstance } from "./axiosSetup";

export async function getReportWithoutAuth(token) {
    return axiosInstance.post(`hub-api/no-auth-proxy`, {
        path: `/reports/dl/${token}`,
        method: "get",
    });
}

export async function updateStatusWithoutAuth(token, body) {
    return axiosInstance.post(`hub-api/no-auth-proxy`, {
        body: body,
        path: `/reports/dl/${token}`,
        method: "patch",
    });
}
