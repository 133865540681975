import React from "react";
import { MDBCol, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { defaultColor } from "../constants";

const LogoWrapper = styled.div`
  .logotype-navbar {
    align-items: center;
    background-color: ${process.env.REACT_APP_PUBLISHER_COLOR || defaultColor};
  }

  .logotype-image {
    height: 60px;
  }
`;

const ClickableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Logo = () => {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/screened-manuscripts");
  };

  return (
    <LogoWrapper>
      <MDBRow className="logotype-navbar text-white m-0">
        <div className="p-3 d-flex flex-row">
          <ClickableWrapper onClick={handleLogoClick}>
            <div className="d-flex justify-content-start flex-column">
              <MDBTypography className="mb-0 fw-bold" variant="h3">
                STM Integrity Hub
              </MDBTypography>
              <MDBTypography variant="h5">Publisher Dashboard</MDBTypography>
            </div>
            <div className="d-flex ms-2">
              {process.env.REACT_APP_PUBLISHER_LOGO ? (
                <img
                  src={`../images/${process.env.REACT_APP_PUBLISHER_LOGO}`}
                  alt="logotype"
                  className="logotype-image"
                />
              ) : (
                <MDBTypography className="mb-0 fw-bold" variant="h3">
                  {process.env.REACT_APP_PUBLISHER_NAME}
                </MDBTypography>
              )}
            </div>
          </ClickableWrapper>
        </div>
        <MDBCol />
      </MDBRow>
    </LogoWrapper>
  );
};

export default Logo;
