import React from "react";
import {
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { AuthProvider, RequireAuth, useAuth } from "./components/Auth";
import { ContextWrapper } from "./components/AppContext";
import Logo from "./components/Logo";
import Navbar from "./components/Navbar";
import Login from "./pages/login/Login";
import ScreenedManuscripts from "./pages/screened-manuscripts/ScreenedManuscripts";
import DuplicateSubmissions from "./pages/duplicate-submissions/DuplicateSubmissions";
import Watchlist from "./pages/watchlist/Watchlist";
import ReferenceAnalysis from "./pages/reference-analysis/ReferenceAnalysis";
import Clearskies from "./pages/clearskies/Clearskies";
import Pubpeer from "./pages/pubpeer/Pubpeer";
import TextAnalysis from "./pages/text-analysis/TextAnalysis";
import MetadataAnalysis from "./pages/MetadataAnalysis/MetadataAnalysis";
import FullScreening from "./pages/full-screening/FullScreening";
import DeepLink from "./pages/deepLink/DeepLink";
import Settings from "./pages/settings/Settings";
import Users from "./pages/users/Users";
import Journals from "./pages/journals/Journals";
import { checkIsAdmin } from "./helpers";

const AuthLayout = () => (
  <>
    <Logo />
    <Outlet />
  </>
);

const MainLayout = () => (
  <>
    <Logo />
    <Navbar />
    <Outlet />
  </>
);

const DeepLinkLayout = () => (
  <>
    <Logo />
    <Outlet />
  </>
);

const EntryPoint = () => {
  const navigate = useNavigate();
  const { login, isFeatureEnabled } = useAuth();

  return (
    <>
      <Login
        navigate={navigate}
        login={login}
        isFeatureEnabled={isFeatureEnabled}
      />
    </>
  );
};
const Layout = () => (
  <ContextWrapper>
    <AuthProvider>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="*" element={<EntryPoint />} />
          <Route path="/login" element={<EntryPoint />} />
        </Route>
        <Route element={<DeepLinkLayout />}>
          <Route path="/dlreport/:token" element={<DeepLink />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route
            path="/screened-manuscripts"
            element={
              <RequireAuth>
                <ScreenedManuscripts />
              </RequireAuth>
            }
          />
          <Route
            path="/duplicate-submissions"
            element={
              <RequireAuth feature={"dupsub"}>
                <DuplicateSubmissions />
              </RequireAuth>
            }
          />
          <Route
            path="/watchlist"
            element={
              <RequireAuth feature={"watchlist"}>
                <Watchlist />
              </RequireAuth>
            }
          />
          <Route
            path="/clearskies"
            element={
              <RequireAuth feature={"clearskies"}>
                <Clearskies />
              </RequireAuth>
            }
          />
          <Route
            path="/pubpeer"
            element={
              <RequireAuth feature={"pubpeer"}>
                <Pubpeer />
              </RequireAuth>
            }
          />
          <Route
            path="/reference-analysis"
            element={
              <RequireAuth feature={"referenceAnalysis"}>
                <ReferenceAnalysis />
              </RequireAuth>
            }
          />
          <Route
            path="/text-analysis"
            element={
              <RequireAuth feature={"textAnalysis"}>
                <TextAnalysis />
              </RequireAuth>
            }
          />
          <Route
            path="/metadata-analysis"
            element={
              <RequireAuth feature={"metadataAnalysis"}>
                <MetadataAnalysis />
              </RequireAuth>
            }
          />
          <Route
            path="/full-screening"
            element={
              // add feature flag
              <RequireAuth >
                <FullScreening />
              </RequireAuth>
            }
          />
          <Route
            path="/settings"
            element={
              <RequireAuth allow={checkIsAdmin()}>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="/users"
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />
          <Route
            path="/journals"
            element={
              <RequireAuth >
                <Journals />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  </ContextWrapper>
);

export default Layout;
