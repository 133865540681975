import {
    MDBContainer,
    MDBTabs,
    MDBTabsContent,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsPane,
} from "mdb-react-ui-kit";
import { useState } from "react";
import SignalsTab from "./tabs/SignalsTab";
import RoutingToolsTab from "./tabs/RoutingToolsTab";
import NotificationRulesTab from "./tabs/NotificationRulesTab";

const TAB_CONFIG = [
    {
        key: "signals",
        label: "Signals",
        component: SignalsTab,
    },
    {
        key: "routing-tools",
        label: "Routing tools",
        component: RoutingToolsTab,
    },
    {
        key: "notification-rules",
        label: "Notification rules",
        component: NotificationRulesTab,
    },
];

const Settings = () => {
    const [currentTab, setCurrentTab] = useState(TAB_CONFIG[0].key);

    const ActiveTabComponent = TAB_CONFIG.find(tab => tab.key === currentTab)?.component;

    return (
        <MDBContainer className="my-5">
            <MDBTabs className="mb-3">
                {TAB_CONFIG.map(({ key, label }) => (
                    <MDBTabsItem key={key} onClick={() => setCurrentTab(key)}>
                        <MDBTabsLink active={currentTab === key}>{label}</MDBTabsLink>
                    </MDBTabsItem>
                ))}
            </MDBTabs>
            <MDBTabsContent>
                <MDBTabsPane show>
                    <ActiveTabComponent isActiveTab />
                </MDBTabsPane>
            </MDBTabsContent>
        </MDBContainer>
    );
};

export default Settings;
