import axios from 'axios';
import { token as TOKEN, apiBaseUrl } from '../constants';

const axiosInstance = axios.create({
    baseURL: apiBaseUrl
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = 'application/json';
    return config;
});

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error?.response?.status === 401) {
            localStorage.removeItem(TOKEN);
            window.location.reload();
        } else {
            throw error;
        }
    }
);

export { axiosInstance };