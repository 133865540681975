import {
  renderCardSection,
  renderAnyHitDetails,
  renderBadActorDetails,
} from "./WatchlistDataReviewUtils.js";
import { getWatchlistData } from "./watchlistUtils";

const WatchlistDataReview = ({ data, onActionSelect, onActionUndo }) => {
  const watchlistData = getWatchlistData(data);
  return (
    <>
      {renderCardSection(
        watchlistData.watchlistFakeEmailDomains,
        "Fake e-mail domains",
        renderAnyHitDetails,
        <>
          Watchlist — The Hub maintains a list of fake email domains that were
          involved in paper mill activities. Domains are sourced from publishers
          and Anna Abalkina. A red flag indicates that a match was found.
        </>,
        onActionSelect,
        onActionUndo,
        data
      )}
      {renderCardSection(
        watchlistData.watchlistFakeAffiliationNames,
        "Fake affiliation names",
        renderAnyHitDetails,
        <>
          Watchlist — The STM Integrity Hub maintains a list of fake email domains
          and institution names that were involved in paper mill activities. A red
          flag indicates that a match was found.
        </>,
        onActionSelect,
        onActionUndo,
        data
      )}
      {renderCardSection(
        watchlistData.watchlistFakeNamesOrEmailAddresses,
        "Fake names or email addresses",
        renderAnyHitDetails,
        <>
          Watchlist — The STM Integrity Hub maintains a list of fake names or
          email addresses that were involved in paper mill activities. A red flag
          indicates that a match was found.
        </>,
        onActionSelect,
        onActionUndo,
        data
      )}
      {renderCardSection(
        watchlistData.watchlistDisposableEmailDomains,
        "Disposable e-mail domains",
        renderAnyHitDetails,
        <>
          Watchlist — The Hub maintains a list of fake email domains that were
          involved in paper mill activities. Domains are sourced from publishers
          and Anna Abalkina. A red flag indicates that a match was found.
        </>,
        onActionSelect,
        onActionUndo,
        data
      )}
      {renderCardSection(
        watchlistData.watchlistBadActors,
        "Bad actors",
        renderBadActorDetails,
        null,
        onActionSelect,
        onActionUndo,
        data
      )}
      {renderCardSection(
        watchlistData.watchlistMetadataSuspects,
        "Metadata suspects",
        renderAnyHitDetails,
        <>
          Watchlist – The STM Integrity Hub maintains a list of suspect names that may occur
          in WORD file metadata. These suspect names were identified by participating publishers.
        </>,
        onActionSelect,
        onActionUndo,
        data
      )}
      {renderCardSection(
        watchlistData.watchlistManuscriptsOfferedForSale,
        "Manuscripts offered for sale",
        renderAnyHitDetails,
        null,
        onActionSelect,
        onActionUndo,
        data
      )}
      {renderCardSection(
        watchlistData.watchlistSuspectArticles,
        "Suspect articles",
        renderAnyHitDetails,
        null,
        onActionSelect,
        onActionUndo,
        data
      )}
    </>
  );
};

export default WatchlistDataReview;
