import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import {
  renderListItems,
  renderWordFilePropertyItem,
} from "./MetadataAnalysisDataUtils";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";
import { InfoTooltip } from "../../../components";
import { ApplyAction } from "../../../components/ApplyAction";
import { dropdownOptions } from "../../screened-manuscripts/dropdownOptions";

const MetadataAnalysisDataReview = ({
  data,
  onActionSelect,
  onActionUndo,
  withSubHeader = false,
}) => {
  const wordFileProperties =
    data?.screening_outcomes?.find((x) => x.type === "wordFileProperties")
      ?.items ?? [];

  return (
    <>
      {wordFileProperties.length > 0 && (
        <MDBCard>
          {withSubHeader && (
            <MDBCardHeader className="d-flex align-items-center">
              <ReportActionButtons
                signals={["wordFileProperties"]}
                showTooltip={false}
              />
              <MDBCardTitle className="pt-2 ps-2">
                Word file metadata
                <InfoTooltip
                  tooltipText={`
                    This signals suspicious patterns in the WORD file metadata,
                    or between the metadata and the manuscript content itself.
                    These patterns were identified by participating publishers.
                  `}
                />
              </MDBCardTitle>
              <div className="ms-auto">
                <ApplyAction
                  index={data?.manuscript_id}
                  status={data?.submission_status}
                  selectAction={onActionSelect}
                  undoAction={onActionUndo}
                  options={dropdownOptions}
                />
              </div>
            </MDBCardHeader>
          )}
          <MDBCardBody>
            {renderListItems(wordFileProperties, renderWordFilePropertyItem)}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default MetadataAnalysisDataReview;
