import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
} from "mdb-react-ui-kit";
import styled from "styled-components";

const DropdownToggleStyled = styled(MDBDropdownToggle)`
  padding-top: 0.2em;
  &::after {
    display: none !important;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`;

const UndoIcon = styled(MDBIcon)`
  cursor: pointer;
`;

export const ApplyAction = ({
  index,
  status,
  selectAction,
  undoAction,
  options = [],
  disabled = false
}) => {
  const handleDropdownItemClick = (e) => {
    e.preventDefault();
    const value = e.currentTarget.dataset.value;
    if (value) selectAction(index, value);
  };

  const handleUndoIconClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    undoAction(index);
  };

  if (disabled) {
    return (
      <ActionWrapper>
        {status && (
          <StatusWrapper >
            <span>{status}</span>
          </StatusWrapper>
        )}
      </ActionWrapper>
    );
 }

  return (
    <ActionWrapper onClick={(e) => e.stopPropagation()}>
      {!status && (
        <MDBDropdown>
          <DropdownToggleStyled color="secondary" className="btn-floating">
            <MDBIcon icon="angle-down" />
          </DropdownToggleStyled>
          <MDBDropdownMenu responsive="end">
            {options.length > 0 ? (
              options.map((option) => (
                <MDBDropdownItem
                  link
                  key={option.value}
                  data-value={option.value}
                  onClick={handleDropdownItemClick}
                >
                  {option.label}
                </MDBDropdownItem>
              ))
            ) : (
              <MDBDropdownItem disabled>No options available</MDBDropdownItem>
            )}
          </MDBDropdownMenu>
        </MDBDropdown>
      )}
      {status && (
        <StatusWrapper>
          <span>{status}</span>
          <UndoIcon
            fas
            icon="arrow-rotate-left"
            size="sm"
            color="primary"
            className="ms-2"
            onClick={handleUndoIconClick}
          />
        </StatusWrapper>
      )}
    </ActionWrapper>
  );
};
