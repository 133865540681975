
const colorizeStatusText = (text) => {
  let coloredText = text;
  if (/confirmed manuscript/gi.test(text)) {
      coloredText = `<span style="color: red;">${text}</span>`;
  }
  else if (/false positive at/gi.test(text) || /submission removed/gi.test(text)) {
      coloredText = `<span style="color: green;">${text}</span>`;
  }
  return coloredText;
};

const ColorizeStatusText = ({ statusText }) => {
  const coloredText = colorizeStatusText(statusText);
  return <div dangerouslySetInnerHTML={{ __html: coloredText }} />;
};

export { ColorizeStatusText };