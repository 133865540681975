import { API_PATHS } from "./apiPaths";
import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";

export const authorize = (credentials) => {
  return axiosInstance.post(API_PATHS.AUTHORIZE, credentials);
};

export const getWsToken = async () => {
  return axiosInstance.get(API_PATHS.WS_TOKEN).catch(handleErrors);
};

export const getUser = async () => {
  return axiosInstance.get(API_PATHS.USER).catch(handleErrors);
};
