import React, { forwardRef } from "react";
import { MDBTypography } from "mdb-react-ui-kit";
import DiffViewer from "react-diff-viewer";

const DuplicateSubmissionsDiffViewer = forwardRef(
  ({ label, oldValue, newValue, similarity, newValueTitle, oldValueTitle }, ref) => {
    if (!oldValue || !newValue) {
      return null;
    }

    const formatValue = (value) =>
      typeof value === "string" ? value : JSON.stringify(value, null, 2);

    return (
      <div className="my-4" ref={ref}>
        <MDBTypography tag="h6">
          {label} {similarity ? ` (Similarity: ${similarity}%)` : ""}
        </MDBTypography>
        <DiffViewer
          oldValue={formatValue(oldValue)}
          newValue={formatValue(newValue)}
          splitView
          showDiffOnly={false}
          leftTitle={oldValueTitle}
          rightTitle={newValueTitle}
        />
      </div>
    );
  }
);

export default DuplicateSubmissionsDiffViewer;
