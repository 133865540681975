import { API_PATHS } from "./apiPaths";
import { axiosInstance } from "./axiosSetup";

export async function getJournals() {
    return axiosInstance.post(`hub-api/proxy`, {
        path: API_PATHS.JOURNALS_LIST,
        method: "get"
    });
}

export const getUsers = async () => {
    return axiosInstance.get(API_PATHS.USERS_LIST);
};

export const getJournalRules = () => {
    return axiosInstance.get(API_PATHS.JOURNAL_RULES_LIST);
}

export const getJournalRulesVersions = () => {
    return axiosInstance.get(API_PATHS.JOURNAL_RULES_VERSIONS_LIST);
}

export const updateJournalRules = (rules) => {
    return axiosInstance.put(API_PATHS.UPDATE_JOURNAL_RULES, rules);
}

export const getNotificationRules = () => {
    return axiosInstance.get(API_PATHS.NOTIFICATION_RULES_LIST);
}

export const getNotificationRulesVersions = () => {
    return axiosInstance.get(API_PATHS.NOTIFICATION_RULES_VERSIONS_LIST);
}

export const updateNotificationRules = (rules) => {
    return axiosInstance.put(API_PATHS.UPDATE_NOTIFICATION_RULES, rules);
}

