import { useState, useEffect } from "react";

import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import Pagination from "../../../components/Pagination/Pagination";
import {
  ReportActionButtons,
  SECTION_KEYS,
} from "../../screened-manuscripts/components/ReportActionButtons";
import {
  ApplyAction,
  ColorizeStatusText,
} from "../../../components/ApplyAction";
import {
  convertISOToReadableDate,
  getAmbientScreeningSubmissionById,
  showErrorToast,
} from "../../../helpers";
import { DataTable, useLoader } from "stm-frontend-library";
import { EmptyInbox, SignalReportModal } from "../../../components";
import { dropdownOptions } from "../dropdownOptions";
import { columns } from "../columns";
import useDuplicateSubmissions from "../hooks/useDuplicateSubmissions";
import useAmbientScreenedManuscripts from "../../screened-manuscripts/hooks/useAmbientScreenedManuscripts";
import { exportAllManuscriptsToExcel } from "../export";

const ManuscriptsTab = ({ type, isActiveTab, exportRef }) => {
  const [selectedItem, setSelectedItem] = useState({
    index: null,
    visibleSection: SECTION_KEYS.ALL,
  });

  const { showLoader, hideLoader, isLoading } = useLoader();
  const [fetchedData, setFetchedData] = useState(null);

  const isInboxTabSelected = type === "wo_status";

  const {
    data,
    fetchData,
    setData,
    setTriggerOrMatchStatus,
    error,
    pageCount,
    currentPage,
    setCurrentPage,
  } = useDuplicateSubmissions({ type, isActiveTab });

  const { setSubmissionStatus } = useAmbientScreenedManuscripts({
    type: "all",
    isActiveTab: true,
  });

  const updateStatus = async (index, status) => {
    const newData = [...data];

    if (isInboxTabSelected) {
      const previousElement = { ...newData[index] };
      newData.splice(index, 1);
      setData(newData);

      const { match_id, persistent_id } = previousElement;
      try {
        await setTriggerOrMatchStatus(match_id, persistent_id, status);
        await fetchData();
      } catch {
        showErrorToast();
        newData.splice(index, 0, previousElement);
        setData(newData);
      }
    } else {
      const previousStatus = newData[index].submission_status;
      newData[index].submission_status = status;

      const { match_id, persistent_id } = newData[index];
      try {
        await setTriggerOrMatchStatus(match_id, persistent_id, status);
      } catch {
        showErrorToast();
        newData[index].submission_status = previousStatus;
      } finally {
        setData(newData);
      }
    }
  };

  const handleIconClick = (index, buttonKey) => {
    setSelectedItem({ index, visibleSection: buttonKey });
  };

  const handleRowClick = (index) => {
    if (index === null) {
      return;
    }
    setSelectedItem({ index, visibleSection: SECTION_KEYS.DUPSUB });
  };

  const onActionSelect = (index, status) => {
    updateStatus(index, status);
  };

  const onActionUndo = (index) => {
    updateStatus(index, null);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const dataWithActions = data?.map((element, index) => ({
    ...element,
    raw_match_status: element.match_status,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    match_status: <ColorizeStatusText statusText={element.match_status} />,
    action: (
      <div className="d-flex">
        <ApplyAction
          index={index}
          status={data[index].submission_status}
          selectAction={onActionSelect}
          undoAction={onActionUndo}
          options={dropdownOptions}
        />
      </div>
    ),
    signals: (
      <ReportActionButtons
        signals={element.signals}
        onIconClick={(buttonKey) => handleIconClick(index, buttonKey)}
      />
    ),
    clickEvent: () => handleRowClick(index),
  }));

  const fetchAmbientScreeningDataIfNeeded = async (index) => {
    const { persistent_id, signals } = data[index];
    if (persistent_id && signals?.some((s) => s !== "duplicateSubmission")) {
      try {
        showLoader();
        const { data: fetchedData } = await getAmbientScreeningSubmissionById(
          persistent_id
        );
        setFetchedData(fetchedData);
      } finally {
        hideLoader();
      }
    }
  };

  const handleExport = () => {
    if (type === "all" && dataWithActions) {
      exportAllManuscriptsToExcel(dataWithActions, "AllManuscripts");
    }
  };

  useEffect(() => {
    if (exportRef && type === "all") {
      exportRef.current = handleExport;
    }
  }, [exportRef, type, dataWithActions]);

  useEffect(() => {
    if (selectedItem.index !== null) {
      fetchAmbientScreeningDataIfNeeded(selectedItem.index);
    } else {
      setFetchedData(null);
    }
  }, [selectedItem, data]);

  const resetModalState = () => {
    setFetchedData(null);
    setSelectedItem({
      index: null,
      visibleSection: SECTION_KEYS.ALL,
    });
  };

  const hasNonDupSubSignals =
    selectedItem.index !== null &&
    !isLoading &&
    data[selectedItem.index]?.signals?.some(
      (x) => x !== "duplicateSubmissions"
    );

  const shouldRenderModal =
    selectedItem.index !== null &&
    (fetchedData || data[selectedItem.index]) &&
    (hasNonDupSubSignals ? fetchedData : true);

  return (
    <>
      {shouldRenderModal && (
        <SignalReportModal
          dupSubData={data[selectedItem.index]}
          contentData={fetchedData}
          visibleSection={selectedItem.visibleSection}
          onClose={resetModalState}
          setTriggerOrMatchStatus={setTriggerOrMatchStatus}
          setSubmissionStatus={setSubmissionStatus}
          fetchData={fetchData}
        />
      )}
      {error && <ErrorMessage />}
      {!error && (
        <>
          <DataTable
            columns={columns}
            rows={dataWithActions}
            customNoDataComponent={isInboxTabSelected ? EmptyInbox : null}
            isLoading={isLoading}
          />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  );
};

export default ManuscriptsTab;