import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MDBContainer, MDBTypography } from "mdb-react-ui-kit";
import { SignalReport } from "../../components/SignalReportModal/SignalReport";
import { showErrorToast } from "../../helpers";
import {
  getReportWithoutAuth,
  updateStatusWithoutAuth,
} from "../../helpers/deepLink";
import { useLoader } from "stm-frontend-library";

const DeepLink = () => {
  const [data, setData] = useState(null);
  const [hasError, setHasError] = useState(false);
  const { token } = useParams();
  const { showLoader, hideLoader } = useLoader();

  const fetchData = async () => {
    if (!token) return;
    try {
      setHasError(false);
      showLoader();
      const { data: response } = await getReportWithoutAuth(token);
      setData(response);
    } catch (error) {
      setHasError(true);
      showErrorToast();
    } finally {
      hideLoader();
    }
  };

  const updateStatus = async ({ component, recordId, submissionStatus }) => {
    try {
      showLoader();
      await updateStatusWithoutAuth(token, {
        component,
        recordId,
        submissionStatus,
      });
      fetchData();
    } catch (error) {
      showErrorToast();
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MDBContainer className="my-5">
      {hasError ? (
        <MDBTypography className="text-center" variant="h5">
          This submission data is unavailable
        </MDBTypography>
      ) : (
        data && (
          <SignalReport
            dupSubData={data.duplicateSubmissions}
            contentData={data.ambientScreening}
            updateDupSubStatus={(itemId, submissionStatus) =>
              updateStatus({
                component: "duplicateSubmissions",
                recordId: itemId,
                submissionStatus,
              })
            }
            updateAmbientScreeningStatus={(itemId, submissionStatus) =>
              updateStatus({
                component: "ambientScreening",
                recordId: itemId,
                submissionStatus,
              })
            }
          />
        )
      )}
    </MDBContainer>
  );
};

export default DeepLink;
