import { useEffect, useState, useCallback, useRef } from "react";
import {
  MDBContainer,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useAppContext } from "../../components/AppContext";
import SearchInput from "../../components/SearchInput/SearchInput";
import ManuscriptsTab from "./tabs/ManuscriptsTab";
import ArchivedManuscriptsTab from "./tabs/ArchivedManuscriptsTab";
import { CountCard } from "../../components";
import { showErrorToast, getDuplicateSubmissionsCount } from "../../helpers";
import { useLoader } from "stm-frontend-library";

const TAB_CONFIG = [
  {
    key: "inbox",
    label: "Inbox",
    type: "wo_status",
    component: ManuscriptsTab,
  },
  {
    key: "all",
    label: "All",
    type: "all",
    component: ManuscriptsTab,
  },
  {
    key: "matched",
    label: "Matched (first submission)",
    type: "matched",
    component: ManuscriptsTab,
  },
  {
    key: "triggers",
    label: "Matching (trigger submission)",
    type: "triggers",
    component: ManuscriptsTab,
  },
  {
    key: "archive",
    label: "Archive",
    type: "archived",
    component: ArchivedManuscriptsTab,
  },
];

const DuplicateSubmissions = () => {
  const { setSearchInput } = useAppContext();

  const [currentTab, setCurrentTab] = useState(TAB_CONFIG[0].key);
  const [manuscriptsCount, setManuscriptsCount] = useState(null);
  const { showLoader, hideLoader, isLoading } = useLoader();

  const exportFunctionRef = useRef(null);

  const handleExport = () => {
    if (exportFunctionRef.current) {
      exportFunctionRef.current();
    }
  };

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        showLoader();
        const { data: { total = 0 } = {} } = await getDuplicateSubmissionsCount();
        setManuscriptsCount(total);
      } catch (error) {
        console.error(error);
        showErrorToast();
      } finally {
        hideLoader();
      }
    };

    fetchCardData();
  }, []);

  const handleTabClick = useCallback((tabKey) => {
    if (currentTab !== tabKey) {
      setCurrentTab(tabKey);
    }
  }, [currentTab]);

  return (
    <MDBContainer className="my-5">
      <div className="d-flex justify-content-center m-5">
        <CountCard
          count={manuscriptsCount ?? 0}
          title="Matches"
          subtitle="Current matches"
        />
      </div>

      <MDBRow>
        <MDBCol md="auto" className="ms-4">
          <MDBTabs className="mb-3">
            {TAB_CONFIG.map(({ key, label }) => (
              <MDBTabsItem key={key} onClick={() => handleTabClick(key)}>
                <MDBTabsLink active={currentTab === key}>{label}</MDBTabsLink>
              </MDBTabsItem>
            ))}
          </MDBTabs>
        </MDBCol>

        <MDBCol sm={8} md={6} lg={4} className="ms-auto">
          {currentTab === "archive" && (
            <SearchInput
              placeholder="Search by manuscript ID or title"
              onSubmit={setSearchInput}
            />
          )}

          {currentTab === "all" && (
            <div className="d-flex justify-content-end mb-3">
              <MDBBtn
                color="primary"
                className="px-4"
                onClick={handleExport}
                disabled={isLoading}
              >
                Export
              </MDBBtn>
            </div>
          )}
        </MDBCol>
      </MDBRow>

      <MDBTabsContent>
        {TAB_CONFIG.map(({ key, component: TabComponent, type }) => (
          <MDBTabsPane key={key} show={currentTab === key}>
            <TabComponent
              isActiveTab={currentTab === key}
              type={type}
              exportRef={type === "all" ? exportFunctionRef : null}
            />
          </MDBTabsPane>
        ))}
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default DuplicateSubmissions;