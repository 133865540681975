import React from "react";
import { ModalWrapper } from "../ModalWrapper";
import { SignalReport } from "./SignalReport";
import { showErrorToast } from "../../helpers";

export const SignalReportModal = ({
  onClose,
  contentData,
  dupSubData,
  visibleSection,
  setTriggerOrMatchStatus,
  setSubmissionStatus,
  fetchData,
}) => {
  const isOpen = !!contentData || !!dupSubData;

  const updateDupSubStatus = async (_, submissionStatus) => {
    if (!dupSubData || !setTriggerOrMatchStatus) return;
    const { match_id, persistent_id } = dupSubData;
    try {
      await setTriggerOrMatchStatus(match_id, persistent_id, submissionStatus);
      fetchData();
    } catch {
      showErrorToast();
    }
  };

  const updateAmbientScreeningStatus = async (_, submissionStatus) => {
    if (!contentData) return;
    const { id } = contentData;
    try {
      await setSubmissionStatus(id, submissionStatus);
      fetchData();
    } catch {
      showErrorToast();
    }
  };

  return (
    <ModalWrapper title={"Signal(s) report"} isOpen={isOpen} onClose={onClose}>
      <SignalReport
        contentData={contentData}
        dupSubData={dupSubData}
        visibleSection={visibleSection}
        updateDupSubStatus={updateDupSubStatus}
        updateAmbientScreeningStatus={updateAmbientScreeningStatus}
      />
    </ModalWrapper>
  );
};
