export const API_PATHS = {
    AUTHORIZE: "authorize",
    WS_TOKEN: "/papermill/wstoken",
    USER: "/user",

    // Clear Skies
    CLEAR_SKIES_LIST: "/reports/tools/clearskies/manuscripts",

    // Duplicate Submissions
    DUPLICATE_SUBMISSIONS_REPORTS_BY_TYPE: "/reports/dupsub/matches",
    ALL_DUPLICATE_SUBMISSIONS: "/reports/dupsub/submissions",
    DUPLICATE_SUBMISSIONS_COUNT: "/reports/dupsub/matches/count",
    DUPLICATE_SUBMISSION_BY_ID: (id) => `/reports/screened/${id}`,
    UPDATE_TRIGGER_OR_MATCH_STATUS: (id) => `/reports/dupsub/matches/${id}`,

    // Ambient Screening
    ALL_AMBIENT_SCREENING_SUBMISSIONS: "/reports/ambient/manuscripts",
    AMBIENT_SCREENING_SUBMISSION_BY_ID: (id) => `/reports/screened/${id}`,
    UPDATE_AMBIENT_SCREENING_SUBMISSION_STATUS: (id) => `/reports/ambient/manuscripts/${id}`,

    // Metadata Analysis
    METADATA_ANALYSIS: "/reports/tools/metadata/manuscripts",

    // PubPeer
    PUBPEER_LIST: "/reports/tools/pubpeer/manuscripts",

    // Reference Analysis
    REFERENCE_ANALYSIS_REPORTS: "/reports/tools/reference/manuscripts",

    // Manuscript Counts
    DUPLICATE_SUBMISSIONS_COUNT_TOTAL: "/reports/dupsub/count",
    AMBIENT_SCREENED_COUNT_TOTAL: "/reports/ambient/count",

    // Export
    EXPORT_ALL_SCREENED_MANUSCRIPT_TABS: "/as/events/export",

    // Text Analysis
    TEXT_ANALYSIS_REPORTS: "/reports/tools/text/manuscripts",

    // Watchlist
    WATCHLIST_REPORTS: "/reports/tools/watchlist/manuscripts",

    // Settings
    JOURNALS_LIST: "/admin/journals/publisher/list",
    USERS_LIST: "/users",
    JOURNAL_RULES_LIST: "/rules/journal",
    JOURNAL_RULES_VERSIONS_LIST: "/rules/journal/versions",
    UPDATE_JOURNAL_RULES: '/rules/journal',
    NOTIFICATION_RULES_LIST: "/rules/notification",
    UPDATE_NOTIFICATION_RULES: "/rules/notification",
    NOTIFICATION_RULES_VERSIONS_LIST: "/rules/notification/versions",
};
