import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBIcon,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
} from "mdb-react-ui-kit";
import { watchlistSignals } from "../../constants";
import DuplicateSubmissionsDataReview from "../../pages/duplicate-submissions/components/DuplicateSubmissionsDataReview";
import ClearskiesDataReview from "../../pages/clearskies/components/ClearskiesDataReview";
import PubpeerDataReview from "../../pages/pubpeer/components/PubpeerDataReview";
import ReferenceAnalysisDataReview from "../../pages/reference-analysis/components/ReferenceAnalysisDataReview";
import MetadataAnalysisDataReview from "../../pages/MetadataAnalysis/components/MetadataAnalysisDataReview";
import TextAnalysisDataReview from "../../pages/text-analysis/components/TextAnalysisDataReview";
import WatchlistDataReview from "../../pages/watchlist/components/WatchlistDataReview";
import { SECTION_KEYS } from "../../pages/screened-manuscripts/components/ReportActionButtons";
import { SignalReportSubmissionCard } from "./SignalReportSubmissionCard";
import { showSuccessToast } from "../../helpers";

const tabs = {
  duplicateSubmission: "duplicateSubmission",
  vendorTools: "vendorTools",
  analysisTools: "analysisTools",
  watchlist: "watchlist",
};

const tabLabels = {
  duplicateSubmission: "Duplicate submissions",
  vendorTools: "Vendor Tools",
  analysisTools: "Analysis Tools",
  watchlist: "Watchlist",
};

const tabSignalMappings = {
  [tabs.duplicateSubmission]: ["duplicateSubmissions"],
  [tabs.vendorTools]: ["clearSkiesStatus", "feetOfClayDetector"],
  [tabs.analysisTools]: [
    "retractionWatch",
    "doiResolutions",
    "wordFileProperties",
    "torturedPhrases",
  ],
};

export const SignalReport = ({
  contentData,
  dupSubData,
  visibleSection = { index: null, visibleSection: SECTION_KEYS.ALL },
  updateDupSubStatus,
  updateAmbientScreeningStatus,
}) => {
  const [currentTab, setCurrentTab] = useState(null);
  const tabVisibility = {
    [tabs.duplicateSubmission]:
      dupSubData?.signals?.some((x) =>
        tabSignalMappings[tabs.duplicateSubmission].includes(x)
      ) ||
      dupSubData?.trigger ||
      dupSubData?.matches,
    [tabs.vendorTools]: contentData?.signals?.some((x) =>
      tabSignalMappings[tabs.vendorTools].includes(x)
    ),
    [tabs.analysisTools]: contentData?.signals?.some((x) =>
      tabSignalMappings[tabs.analysisTools].includes(x)
    ),
    [tabs.watchlist]: contentData?.signals?.some((x) =>
      watchlistSignals.map((signal) => signal.value).includes(x)
    ),
  };

  useEffect(() => {
    const tabKeyMap = {
      [SECTION_KEYS.DUPSUB]: tabs.duplicateSubmission,
      [SECTION_KEYS.CLEARSKIES]: tabs.vendorTools,
      [SECTION_KEYS.PUBPEER]: tabs.vendorTools,
      [SECTION_KEYS.METADATA_ANALYSIS]: tabs.analysisTools,
      [SECTION_KEYS.REFERENCE_ANALYSIS]: tabs.analysisTools,
      [SECTION_KEYS.TEXT_ANALYSIS]: tabs.analysisTools,
      [SECTION_KEYS.WATCHLIST]: tabs.watchlist,
    };
    setCurrentTab(
      tabKeyMap[visibleSection] ||
        Object.keys(tabVisibility).find((tab) => tabVisibility[tab])
    );
  }, [dupSubData, contentData]);

  const onAmbientScreeningActionSelect = async (id, status) => {
    if (contentData?.id) {
      await updateAmbientScreeningStatus(contentData.id, status);
    }
  };

  const onAmbientScreeningActionUndo = async () => {
    if (contentData?.id) {
      await updateAmbientScreeningStatus(contentData.id, null);
    }
  };

  const onDupSubActionSelect = async (_, status) => {
    if (dupSubData?.match_id) {
      await updateDupSubStatus(dupSubData.match_id, status);
    }
  };

  const handleIconClick = () => {
    const link = dupSubData?.deeplink || contentData?.deeplink;
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          showSuccessToast("Link copied successfully");
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
        });
    }
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case tabs.duplicateSubmission:
        return (
          <DuplicateSubmissionsDataReview
            data={dupSubData}
            withSubHeader
            onActionSelect={onDupSubActionSelect}
          />
        );
      case tabs.vendorTools:
        return (
          <>
            <SignalReportSubmissionCard data={contentData} />
            <ClearskiesDataReview
              data={contentData}
              onActionSelect={onAmbientScreeningActionSelect}
              onActionUndo={onAmbientScreeningActionUndo}
              withSubHeader
            />
            <PubpeerDataReview
              data={contentData}
              onActionSelect={onAmbientScreeningActionSelect}
              onActionUndo={onAmbientScreeningActionUndo}
              withSubHeader
            />
          </>
        );
      case tabs.analysisTools:
        return (
          <>
            <SignalReportSubmissionCard data={contentData} />
            <ReferenceAnalysisDataReview
              data={contentData}
              onActionSelect={onAmbientScreeningActionSelect}
              onActionUndo={onAmbientScreeningActionUndo}
            />
            <MetadataAnalysisDataReview
              data={contentData}
              onActionSelect={onAmbientScreeningActionSelect}
              onActionUndo={onAmbientScreeningActionUndo}
              withSubHeader
            />
            <TextAnalysisDataReview
              data={contentData}
              onActionSelect={onAmbientScreeningActionSelect}
              onActionUndo={onAmbientScreeningActionUndo}
            />
          </>
        );
      case tabs.watchlist:
        return (
          <>
            <SignalReportSubmissionCard data={contentData} />
            <WatchlistDataReview
              data={contentData}
              onActionSelect={onAmbientScreeningActionSelect}
              onActionUndo={onAmbientScreeningActionUndo}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <MDBTabs className="mb-3 d-flex align-items-center">
        <div className="d-flex">
          {Object.keys(tabLabels).map(
            (tab) =>
              tabVisibility[tab] && (
                <MDBTabsItem key={tab} onClick={() => setCurrentTab(tab)}>
                  <MDBTabsLink active={currentTab === tab}>
                    {tabLabels[tab]}
                  </MDBTabsLink>
                </MDBTabsItem>
              )
          )}
        </div>
        <MDBBtn
          className="ms-auto"
          color="info"
          floating
          tag="a"
          onClick={handleIconClick}
        >
          <MDBIcon fas icon="link" />
        </MDBBtn>
      </MDBTabs>
      <MDBTabsContent>{renderTabContent()}</MDBTabsContent>
    </>
  );
};
