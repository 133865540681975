export const dropdownOptions = [
  {
    label: "Confirm manuscript & report to other publisher(s)",
    value: "duplicate confirmed",
  },
  {
    label: "Paper Mill Suspect & confirm manuscript to other publisher(s)",
    value: "suspect paper mill",
  },
  {
    label: "False positive (wrongfully in corpus), remove from corpus & inform publisher(s)",
    value: "false positive",
  },
  {
    label: "False positive (manuscripts wrongfully matched), flag as false match & inform publisher(s)",
    value: "false match",
  },
  {
    label: "Legitimate duplicate, ignore",
    value: "ignored",
  },
  {
    label: "Hide from inbox",
    value: "hidden",
  },
];
