import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { InfoTooltip } from "../../../components";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";
import { ApplyAction } from "../../../components/ApplyAction";
import { dropdownOptions } from "../../screened-manuscripts/dropdownOptions";

export const renderField = (label, value) => {
  return value ? (
    <p>
      <strong>{label}:</strong> {value}
    </p>
  ) : null;
};

export const renderAnyHitDetails = (item, index, array) => (
  <div key={index}>
    {renderField("Value", item?.value)}
    {renderField("Provided by", item?.providedby)}
    {renderField("Timestamp", item?.timestamp)}
    {renderField("Comment", item?.comment)}
    {index < array.length - 1 && <hr className="hr" />}
  </div>
);

export const renderBadActorDetails = (item, index, array) => (
  <div key={index}>
    {renderField("Name", item?.value?.name)}
    {renderField("Institution", item?.value?.institution)}
    {renderField("Email", item?.value?.email)}
    {renderField("Phone", item?.value?.phone)}
    {renderField("ORCID", item?.value?.ORCID)}
    {renderField("IP address", item?.sourceValue?.ip)}
    {renderField("Breach type", item?.breach)}
    {renderField("Provided by", item?.providedby)}
    {renderField("Timestamp", item?.timestamp)}
    {renderField("Comment", item?.comment)}
    {index < array.length - 1 && <hr className="hr" />}
  </div>
);

export const renderCardSection = (
  items,
  title,
  renderFunction,
  tooltipText = null,
  onActionSelect,
  onActionUndo,
  data
) => {
  if (items.length === 0) return null;

  return (
    <MDBCard className="mt-3">
      <MDBCardHeader className="d-flex align-items-center">
        <ReportActionButtons signals={["watchlist"]} showTooltip={false} />
        <MDBCardTitle className="pt-2 ps-2">
          {title}
          {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
        </MDBCardTitle>
        <div className="ms-auto">
          <ApplyAction
            index={data?.manuscript_id}
            status={data?.submission_status}
            selectAction={onActionSelect}
            undoAction={onActionUndo}
            options={dropdownOptions}
          />
        </div>
      </MDBCardHeader>
      <MDBCardBody>
        {items.map((item, index, array) => renderFunction(item, index, array))}
      </MDBCardBody>
    </MDBCard>
  );
};
