import { API_PATHS } from "./apiPaths";
import { axiosInstance } from "./axiosSetup";

export async function getDuplicateSubmissionsReportsByType(
  startrow,
  maxrows,
  type,
  filter
) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      maxrows: maxrows,
      startrow: startrow,
      filter: filter,
      type: type, // 'all' | 'triggers' | 'matched' | 'wo_status'
    },
    path: API_PATHS.DUPLICATE_SUBMISSIONS_REPORTS_BY_TYPE,
    method: "get",
  });
}

export async function getAllDuplicateSubmissions(startrow, maxrows, filter) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      maxrows: maxrows,
      startrow: startrow,
      filter: filter,
    },
    path: API_PATHS.ALL_DUPLICATE_SUBMISSIONS,
    method: "get",
  });
}

export async function getDuplicateSubmissionsCount() {
  return axiosInstance.post(`hub-api/proxy`, {
    path: API_PATHS.DUPLICATE_SUBMISSIONS_COUNT,
    method: "get",
  });
}

export async function getDuplicateSubmissionById(id) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      type: "duplicateSubmissions",
    },
    path: API_PATHS.DUPLICATE_SUBMISSION_BY_ID(id),
    method: "get",
  });
}

export async function updateTriggerOrMatchStatus(id, persistentId, submissionStatus) {
  return axiosInstance.post(`hub-api/proxy`, {
    path: API_PATHS.UPDATE_TRIGGER_OR_MATCH_STATUS(id),
    method: "patch",
    body: { persistentId, submissionStatus }
  });
}

export async function getAllAmbientScreeningSubmissions(startrow, maxrows, filter, type) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      maxrows, startrow, filter, type
    },
    path: API_PATHS.ALL_AMBIENT_SCREENING_SUBMISSIONS,
    method: "get",
  });
}

export async function getAmbientScreeningSubmissionById(id) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      type: "ambientScreening",
    },
    path: API_PATHS.AMBIENT_SCREENING_SUBMISSION_BY_ID(id),
    method: "get",
  });
}

export async function updateAmbientScreeningSubmissionStatus(id, submissionStatus) {
  return axiosInstance.post(`hub-api/proxy`, {
    path: API_PATHS.UPDATE_AMBIENT_SCREENING_SUBMISSION_STATUS(id),
    method: "patch",
    body: { submissionStatus }
  });
}
